<template>
  <nav class="navbar">
      <router-link to="/gallery" style="text-decoration: none; color: inherit;" class="top"><h1>solomonica</h1></router-link>
    <input
      type="checkbox"
      id="check"
      class="checkbox"
      hidden
      v-model="checkboxValue"
    />
    <div class="hamburger-menu">
      <label for="check" class="menu">
        <div class="menu-line menu-line-1"></div>
        <div class="menu-line menu-line-2"></div>
        <div class="menu-line menu-line-3"></div>
      </label>
    </div>
    <div class="navbar-navigation">
      <div class="navbar-navigation-left">
        <div class="top-bar">
          <router-link to="/about" style="text-decoration: none; color: inherit"
            ><li><h2>About</h2></li></router-link
          >
          <router-link to="/film" style="text-decoration: none; color: inherit"
            ><li><h2>Film</h2></li></router-link
          >
          <router-link
            to="/models"
            style="text-decoration: none; color: inherit"
            ><li><h2>Models</h2></li></router-link
          >
          <router-link
            to="/actors"
            style="text-decoration: none; color: inherit"
            ><li><h2>Actors</h2></li></router-link
          >
          <router-link
            to="/musicians"
            style="text-decoration: none; color: inherit"
            ><li><h2>Musicians</h2></li></router-link
          >
          <router-link
            to="/wedding"
            style="text-decoration: none; color: inherit"
            ><li><h2>Wedding</h2></li></router-link
          >
          <router-link
            to="/contact"
            style="text-decoration: none; color: inherit"
            ><li><h2>Contact</h2></li></router-link
          >
          <li>
            <p class="copyright">© solomonica 2021 - All Rights Reserved</p>
          </li>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      checkboxValue: false,
    };
  },
  methods: {
    uncheck() {
      this.checkboxValue = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: block;
  justify-content: center;
  text-align: center;
  margin: 0;
  position: fixed;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Raleway", sans-serif;
  h1 {
    color: white;
    font-size: 35px;
    letter-spacing: 1px;
    font-weight: 300;
    font-family: 'Libre Barcode 39 Text', cursive;
  }
}
.navbar {
  position: relative;
  z-index: 200;

  &-navigation {
    display: flex;

    &-left {
      height: 100vh;
      background: linear-gradient(rgba(black, 0.8), rgba(black, 0.8)),
        center no-repeat;
      background-size: cover;
      position: fixed;
      width: 100vw;
      position: fixed;
      left: -100vw;
      transition: left 0.2s cubic-bezier(1, 0, 0, 1);
    }
  }
}
.hamburger-menu {
  width: 30px;
  height: 30px;
  position: fixed;
  top: 3%;
  right: 5%;
  z-index: 300;

  .menu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    transition: transform 0.5s;

    &-line {
      width: 100%;
      height: 2px;
      background-color: white;
      transform-origin: right;
      transition: all 0.3s 0.3s;
    }
  }
}
.top-bar {
  color: white;
  display: block;
  justify-content: center;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Raleway", sans-serif;
  .copyright {
    font-size: 11px;
    font-weight: 600;
    opacity: 0.8;
  }
  li {
    list-style: none;
    margin: 20px;
    // display: flex;
  }
}
.logo {
  position: fixed;
  top: 7.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
h2 {
  &:hover {
    color: pink;
  }
}

.checkbox:checked ~ .navbar-navigation .navbar-navigation-left {
  left: 0;
}
.checkbox:checked ~ .hamburger-menu .menu {
  transform: rotateZ(90deg);
}
.checkbox:checked ~ .hamburger-menu .menu-line-1 {
  transform: rotateZ(-40deg);
}
.checkbox:checked ~ .hamburger-menu .menu-line-2 {
  opacity: 0;
}
.checkbox:checked ~ .hamburger-menu .menu-line-3 {
  transform: rotateZ(40deg);
}
</style>
